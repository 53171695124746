<template>
    <div>
        <a-form :form="form" :model="formObj">
            <tableTitle
                ref="tableTitle"
                :addType="3"
                v-model="formObj.name"
                placeholder="请输入处理笺名称"
            ></tableTitle>
            <table class="mytb draft_table">
                <tr class="thtt">
                    <th>发文字号</th>
                    <td colspan="3">
                        <a-form-item label="">
                            <a-input
                                :maxLength="40"
                                placeholder="请输入字号，例：〔2022〕88 号"
                                v-decorator="['fwzh', { rules: this.fastValidateRules.documentNumber }]"
                            />
                        </a-form-item>
                    </td>
                </tr>
                <tr class="thtt">
                    <th>紧急程度</th>
                    <td>
                        <a-form-item label="">
                            <a-select
                                placeholder="请选择"
                                v-decorator="[
                                    'jjcd',
                                    {
                                        initialValue:
                                            (flow_urgency_degree.length > 0 &&
                                                flow_urgency_degree[flow_urgency_degree.length - 1].code) ||
                                            '1',
                                        rules: this.validateRules.urgencyDegree,
                                    },
                                ]"
                            >
                                <a-select-option
                                    v-for="(item, index) in flow_urgency_degree"
                                    :key="index"
                                    :value="item.code"
                                    >{{ item.value }}</a-select-option
                                >
                            </a-select>
                        </a-form-item>
                    </td>
                    <th>密级程度</th>
                    <td>
                        <a-form-item label="">
                            <a-select
                                placeholder="请选择"
                                v-decorator="[
                                    'miji',
                                    {
                                        initialValue:
                                            (flow_secrecy_degree.length > 0 &&
                                                flow_secrecy_degree[flow_secrecy_degree.length - 1].code) ||
                                            '1',
                                        rules: this.validateRules.secrecyDegree,
                                    },
                                ]"
                            >
                                <a-select-option
                                    v-for="(item, index) in flow_secrecy_degree"
                                    :key="index"
                                    :value="item.code"
                                    >{{ item.value }}</a-select-option
                                >
                            </a-select>
                        </a-form-item>
                    </td>
                </tr>
                <tr class="thtt">
                    <th colspan="1">拟稿单位</th>
                    <td colspan="3">
                        <a-form-item label="">
                            <!-- <a-input hidden v-decorator="['draftCompany']" /> -->
                            <div class="list_content" style="text-align: left; padding-left: 8px">
                                <div class="flex">
                                    <a-button
                                        icon="user"
                                        @click="
                                            () => {
                                                updateFirstChangeState()
                                                chooseDepart('ngdw_list', 1)
                                            }
                                        "
                                    ></a-button>
                                    <template v-if="ngdw_list && ngdw_list.length > 0">
                                        <a-tag
                                            class="tags flex0"
                                            v-for="(o, i) in ngdw_list"
                                            :key="'ngdw_list' + i"
                                            color="blue"
                                            style="margin-bottom: 0; height: 32px; margin-left: 10px; line-height: 28px"
                                            closable
                                            @close.prevent="
                                                () => {
                                                    updateFirstChangeState()
                                                    ngdw_list.splice(i, 1)
                                                }
                                            "
                                        >
                                            <span
                                                class="tags flex0"
                                                color="blue"
                                                v-if="o.corpName"
                                                :key="'ngdw_list_span' + i"
                                                >{{ o.corpName }}</span
                                            >
                                            <OpenData
                                                v-else
                                                :type="o.type"
                                                :openid="o.userId || o.id"
                                                :corpid="o.corpId"
                                            ></OpenData>
                                        </a-tag>
                                    </template>
                                    <div class="inputs" v-else>
                                        <a-input
                                            :maxLength="30"
                                            v-model="formObj.ngdwListInput"
                                            placeholder="请输入或选择拟稿单位"
                                            @change="
                                                () => {
                                                    updateFirstChangeState()
                                                }
                                            "
                                        ></a-input>
                                    </div>
                                </div>
                            </div>
                            <template v-if="draftCompanyValidateState || fromValidateState">
                                <div
                                    style="color: #f5222d"
                                    v-if="!getFormValue(formObj.ngdwListInput || formObj.draftCompany)"
                                    class="ant-form-explain"
                                >
                                    {{ this.fastValidateRules.draftCompany[0].message }}
                                </div>
                            </template>
                        </a-form-item>
                    </td>
                </tr>
                <tr class="thtt">
                    <th>拟稿人</th>
                    <td>
                        <a-form-item label="">
                            <!-- <a-input v-decorator="['draftId', {}]" /> -->
                            <div class="list_content" style="text-align: left; padding-left: 8px">
                                <a-tag
                                    class="tags flex0"
                                    color="blue"
                                    closable
                                    v-for="(o, i) in ngry_list"
                                    :key="'ngry_list' + i"
                                    style="height: 32px; line-height: 28px"
                                    @close.prevent="
                                        () => {
                                            updateFirstChangeState()
                                            ngry_list.splice(i, 1)
                                        }
                                    "
                                >
                                    <OpenData :type="o.type" :openid="o.userId || o.id" :corpid="o.corpId"></OpenData>
                                </a-tag>
                                <a-button
                                    slot="suffix"
                                    icon="user"
                                    @click="
                                        () => {
                                            updateFirstChangeState()
                                            showSelctDialog('ngry_list', 2)
                                        }
                                    "
                                ></a-button>
                            </div>
                            <template v-if="draftIdValidateState || fromValidateState">
                                <div
                                    style="color: #f5222d"
                                    v-if="!getFormValue(formObj.draftId)"
                                    class="ant-form-explain"
                                >
                                    {{ this.fastValidateRules.draftId[0].message }}
                                </div>
                            </template>
                        </a-form-item>
                    </td>
                    <th>联系电话</th>
                    <td>
                        <a-form-item label="">
                            <a-input
                                placeholder="请输入"
                                v-decorator="['lxdh', { rules: this.validateRules.tellPhone }]"
                            />
                        </a-form-item>
                    </td>
                </tr>
                <tr class="thtt">
                    <th>文稿标题</th>
                    <td colspan="3">
                        <a-form-item label="">
                            <a-input
                                placeholder="请输入"
                                :maxLength="60"
                                v-decorator="['wgbt', { rules: this.validateRules.title }]"
                            />
                        </a-form-item>
                    </td>
                </tr>

                <tr class="thtt">
                    <th>正文</th>
                    <td colspan="3" style="text-align: left" :style="{ 'padding-left': content ? '0' : '' }">
                        <a-form-item v-if="!content">
                            <a-input
                                v-decorator="['content', { rules: this.validateRules.content }]"
                                @click.native="tanslateTab"
                                placeholder="请输入正文"
                                readOnly
                            />
                        </a-form-item>
                        <div v-else @click="tanslateTab" class="upload_text htmlContainer">
                            <showEditor :content="content" :editorId="'draft_table_editor'"></showEditor>
                        </div>
                    </td>
                </tr>
                <tr class="thtt align_l">
                    <th>附件</th>
                    <td colspan="3">
                        <template>
                            <a-upload
                                name="file1"
                                :customRequest="customRequest"
                                :openFileDialogOnClick="!loading"
                                :showUploadList="false"
                                :maxCount="1"
                            >
                                <a-button size="small" type="primary" ghost icon="cloud-download" :loading="loading"
                                    >上传附件</a-button
                                >
                            </a-upload>
                        </template>

                        <template>
                            <div class="upload_file" v-for="(o, i) in fileFileIdss" :key="i">
                                {{ o.fileName }}
                                <a-button type="link" size="small" icon="delete" @click="fileFileIdss.splice(i, 1)" />
                            </div>
                        </template>
                    </td>
                </tr>

                <tr class="bigtt">
                    <th>拟办意见</th>
                    <td colspan="3">
                        <a-form-item label="">
                            <a-textarea
                                placeholder="请输入"
                                :maxLength="200"
                                v-decorator="['nbyj', { rules: this.validateRules.deviseOpinion }]"
                                :autosize="{ minRows: 5, maxRows: 10 }"
                            />
                        </a-form-item>
                    </td>
                </tr>

                <template>
                    <template v-if="editType == 'renewal'">
                        <nodes :list="formatList(info.documentNodeList)" />
                    </template>
                    <template v-else>
                        <tr v-for="(o, i) in list" :key="i" class="bigtt align_l">
                            <th>{{ o.name }}</th>
                            <td colspan="3">
                                <a-tag color="#108ee9" v-for="tag in o.val" :key="tag.id">
                                    <OpenData
                                        :type="tag.type"
                                        :openid="tag.userId || tag.id"
                                        :corpid="tag.corpId"
                                    ></OpenData>
                                </a-tag>
                            </td>
                        </tr>
                    </template>
                </template>
            </table>
        </a-form>
        <selectPersonDialog ref="selectPersonDialog" @selectTodoMsg="selectTodoMsg" />
    </div>
</template>

<script>
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { sysFileInfoUpload } from '@/api/modular/system/fileManage'
import { handleUploadCommon, selectPerson } from '@/mixins'
import nodes from '@/views/fileStream/documentApproval/documentManage/nodes.vue'
import tableTitle from '@/views/fileStream/documentManage/components/tableTitle.vue'
import { handleNodeList } from '@/views/fileStream/documentManage/minxins'
import { commonFromValidate, customFromValidate } from '@/views/fileStream/documentManage/minxins.js'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import selectPersonAndDepart, {
    corpId,
    dealResult,
    deptId,
    getUserListMsg,
    userId,
} from '@/views/fileStream/documentManage/weixin/selectPerson'
import selectPersonDialog from '@/views/fileStream/documentManage/weixin/selectPersonDialog'
import showEditor from '@/views/fileStream/documentSend/components/showEditor.vue'
import moment from 'moment'
import { EventBus } from '../documentManage/bus.js'
import { loadDefaultName } from './mixins'
export default {
    props: {
        content: {
            type: String,
            default: '',
        },
        //收文信息
        info: {
            type: Object,
            default() {
                return {}
            },
        },
        editType: {
            type: String,
            default: '',
        },
    },
    components: {
        OpenData,
        selectPersonDialog,
        tableTitle,
        showEditor,
        nodes,
    },
    mixins: [loadDefaultName, selectPerson, handleNodeList, commonFromValidate, customFromValidate, handleUploadCommon],
    data() {
        return {
            loading: false,
            moment,
            dom: [],
            form: this.$form.createForm(this, {
                onValuesChange: this.onValuesChange,
            }),
            flow_secrecy_degree: [],
            flow_urgency_degree: [],
            list: [{ name: '领导审批', val: [] }],
            ngdw_list: [],
            ngry_list: [],
            formObj: {
                name: '发文稿纸',
                ngdw_list: [],
                ngdwListInput: '',
                ngry_list: [
                    {
                        type: 'userName',
                        corpId,
                        userId,
                    },
                ],
            },
            type: '',
            fileFileIdss: [],
        }
    },
    computed: {
        isFieldValidating() {
            return false
        },
    },
    watch: {
        formObj: {
            handler(val) {
                EventBus.$emit('form_state', val, this.form)
            },
            immediate: true,
        },
        // 拟稿单位
        ngdw_list: {
            handler(val) {
                this.updateValidateState('draftCompany', !(val.length > 0))
                this.$set(this.formObj, 'draftCompany', val)
            },
            deep: true,
            immediate: true,
        },
        'formObj.ngdwListInput': {
            handler(val) {
                this.updateValidateState('draftCompany', !val)
            },
        },
        // 拟稿人
        ngry_list: {
            handler(val) {
                this.updateValidateState('draftId', !(val.length > 0))
                this.$set(this.formObj, 'draftId', val)
            },
            deep: true,
            immediate: true,
        },
        info: {
            handler(val) {
                const { fileIds, draftId, draftCompany } = val
                if (fileIds) {
                    this.fileFileIdss = Array.isArray(fileIds) ? fileIds : []
                }
                if (draftId) {
                    this.ngry_list = Array.isArray(draftId) ? draftId : []
                }
                if (draftCompany) {
                    this.ngdw_list = Array.isArray(draftCompany) ? draftCompany : []
                }
                this.formObj = val
            },
            immediate: true,
            // deep: true,
        },
    },
    destroyed() {
        EventBus.$off('addDom', {})
    },
    created() {
        this.getDict()
        this.getList()
        this.getFromValidateState()
        this.documentTabDefaultByType(3, 'formObj')
    },
    methods: {
        getFormObj() {
            const {
                form: { getFieldsValue },
            } = this
            let params = getFieldsValue()
            return { ...this.formObj, ...params, ngry_list: this.ngry_list, ngdw_list: this.ngdw_list }
        },
        reset() {
            this.formObj = {
                name: '发文稿纸',
                ngdw_list: [
                    {
                        type: 'departmentName',
                        corpId,
                        id: deptId,
                    },
                ],
                ngry_list: [
                    {
                        type: 'userName',
                        corpId,
                        userId,
                    },
                ],
            }
            this.fileFileIdss = []
            this.ngdw_list = [
                {
                    type: 'departmentName',
                    corpId,
                    id: deptId,
                },
            ]
            this.ngry_list = [
                {
                    type: 'userName',
                    corpId,
                    userId,
                },
            ]
        },
        /**
         * 上传文件
         */
        customRequest(data) {
            const size = data.file.size / 1024 / 1024 > 100
            // const fileName = data.file.name
            // const fileType = ['.doc', '.docx', '.pdf', '.xls', 'xlsx', '.png', '.jpg'] // .doc,.docx,image/*,.pdf,.xls,.xlsx
            let isRepeat = this.handleRepeatForFileName(data.file, this.formObj.fileIds)
            if (isRepeat) {
                return this.$message.error('上传失败：上传文件相同')
            }
            if (size) {
                this.$message.error('上传失败：文件大于100M')
                return
            }
            if (this.loading) return
            this.loading = true
            const formData = new FormData()
            formData.append('file', data.file)
            sysFileInfoUpload(formData)
                .then((res) => {
                    if (res.success) {
                        this.$message.success('上传成功')
                        const obj = {
                            fileName: data.file.name,
                            fileId: res.data,
                            fileType: '1',
                        }
                        this.fileFileIdss = [...this.fileFileIdss, obj]
                        this.formObj.fileIds = this.fileFileIdss
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        chooseDepart(list, index) {
            this.type = 1
            this.selectPersonAndPart(list, index, 'single')
        },
        selectPersonAndPart(list, index, mode = 'multi') {
            const {
                selectedOpenUserIds,
                selectedCorpGroupUserIds,
                selectedDepartmentIds,
                selectedCorpGroupDepartmentIds,
            } = getUserListMsg(list, index, this[list])
            selectPersonAndDepart({
                selectedOpenUserIds,
                selectedCorpGroupDepartmentIds,
                selectedDepartmentIds,
                selectedCorpGroupUserIds,
                mode,
                type: this.type, // 1 部门 其他 员工
            })
                .then((res) => {
                    const { result, resPart } = dealResult(res)
                    this.$set(this, list, [...result, ...resPart])
                    this.formObj[list] = [...result, ...resPart]
                    if (list === 'ngdw_list') this.formObj.ngdwListInput = ''
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        // 通讯录弹窗 // type 其他（员工）/1部门
        showSelctDialog(key, type, index) {
            type && (this.type = type)
            this.$refs.selectPersonDialog.open({ key, index })
        },
        objectEquail(obj1, obj2) {
            return JSON.stringify(obj1) === JSON.stringify(obj2)
        },
        choosePerson(list, index) {
            this.selectPersonAndPart(list, index)
        },
        selectTodoMsg({ type, data: { key, index, result } }) {
            if (type === 'book') {
                this.choosePerson(key, index)
            } else if (type === 'label') {
                if (key === 'list') {
                    result.forEach((ele) => {
                        if (!this[key][index].val.some((j) => this.objectEquail(j, ele))) {
                            this[key][index].val.push(ele)
                        }
                    })
                } else {
                    result.forEach((element) => {
                        if (!this[key].some((j) => this.objectEquail(j, element))) {
                            this[key].push(element)
                        }
                    })
                }
                this.formObj[key] = this[key]
            }
        },

        // 获取字典
        getDict() {
            sysDictTypeDropDown({ code: 'flow_secrecy_degree' }).then((res) => {
                this.flow_secrecy_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_urgency_degree' }).then((res) => {
                this.flow_urgency_degree = res.data
            })
        },
        // 情况表单
        clear_form() {
            this.form.resetFields()
            // this.ngdw_list = []
        },
        Filter(s) {
            const values = this.flow_secrecy_degree.filter((item) => item.code == s)
            if (values.length > 0) {
                return values[0].value
            }
        },
        formatJjcd(jjcd) {
            return this.flow_urgency_degree.find((j) => j.code === jjcd)?.value
        },
        tanslateTab() {
            this.$emit('tanslateTab')
        },

        onChange(date, dateString) {
            console.log(date, dateString)
        },
        getList() {
            EventBus.$on('addDom', (msg) => {
                this.list = msg
            })
        },
    },
}
</script>
<style lang="less" scoped>
.flex {
    display: flex;
    justify-content: space-between;
}
.inputs {
    display: inline-block;
}
::v-deep .ant-select-selection,
::v-deep .ant-input {
    border: none;
    box-shadow: none;
    &:focus {
        box-shadow: none;
    }
}

.upload_file {
    display: flex;
    align-items: center;
    line-height: 0.7;
}
.font {
    font-size: 16px;
}

.tags {
    margin-bottom: 2px;
}
.upload_text {
    cursor: pointer;
    &.htmlContainer {
        min-height: 200px;
    }
    td {
        border-style: solid !important;
    }
}
.mytb {
    width: 100%;
    border: 1px solid black;
    font-size: 14px;
    table-layout: fixed;
    th,
    td {
        text-align: center;
        border: 1px solid black;
    }
    .thtt {
        height: 50px;
    }
    .tdtt {
        height: 50px;
    }
    .bigtt {
        height: 150px;
    }
    .smalltt {
        height: 50px;
    }
    .align_l {
        td {
            text-align: left;
            padding: 15px;
            vertical-align: text-top;
            white-space: pre-line;
        }
    }
}
.draft_table {
    .list_content {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .my-item {
            width: 40%;
            margin: 0 5px;
        }
        .list_btn {
            width: 100%;
        }
    }
    /deep/ .ant-form-item {
        margin-bottom: 0;
    }
    ::v-deep .ant-select-selection,
    ::v-deep .ant-input {
        border: none;
        box-shadow: none;
        &:focus {
            box-shadow: none;
        }
    }
}
.tags {
    // line-height: 32px;
}
.title {
    height: 60px;
    width: 50%;
    margin: 0 auto;
    /deep/ .ant-input {
        color: red;
        font-size: 26px;
        text-align: center;
        font-weight: bold;
    }
}
</style>
